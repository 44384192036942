import React from 'react';
import ApolloClient from 'apollo-boost';
import { gql } from 'apollo-boost';
import Layout from '../layouts/nl';
import config from '../../config';
import { Link } from 'gatsby';
import iconLogin from '../assets/images/icons/icon-login.svg';
import iconLoginHover from '../assets/images/icons/icon-login-hover.svg';
import iconDown from '../assets/images/icons/icon-down.svg';
import iconGeography from '../assets/images/icons/icon-geography.svg';
import iconCaretDown from '../assets/images/icons/icon-caret-down.svg';
import iconCaretRight from '../assets/images/icons/icon-caret-right.svg';
import iconInovation from '../assets/images/icons/icon-inovation.svg';
import iconMoney from '../assets/images/icons/icon-money.svg';
import iconPlanet from '../assets/images/icons/icon-planet.svg';
import iconDownBlue from '../assets/images/icons/icon-down-blue.svg';
import iconTransform from '../assets/images/icons/icon-transform.svg';
import iconMap from '../assets/images/icons/icon-map.svg';
import iconSubmit from '../assets/images/icons/icon-submit.svg';
import iconSuitcase from '../assets/images/icons/icon-suitcase.svg';
import iconPhone from '../assets/images/icons/icon-phone.svg';
import iconAddress from '../assets/images/icons/icon-address.svg';
import iconMessage from '../assets/images/icons/icon-message.svg';
import iconRightBlue from '../assets/images/icons/icon-double-right.svg';
import iconMagnifierRemove from '../assets/images/icons/icon-magnifier-remove.svg';
import iconReporting from '../assets/images/icons/icon-reporting.svg';
import iconSustainable from '../assets/images/icons/icon-sustainable-green.svg';
import keyFactsImage from '../assets/images/key-facts-2.jpg';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import timboLogo from '../assets/images/timbo-logo.png';
import timboAfrica from '../assets/images/timbo-africa_2.jpg';
// import timboAfricaMobile from '../assets/images/timbo-africa-mobile.png';
import Scroll, { scrollTo } from './../components/Scroll';
import contactImage from '../assets/images/contact-background_2.jpg';
import contactImageMobile from '../assets/images/contact-background-mobile_2.jpg';
import Spinner from './../components/Spinner';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import fetch from 'unfetch';
import { BrowserView, MobileView } from 'react-device-detect';
import axios from 'axios';
import noImage from '../assets/images/no-image.png';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { loadByKey } from '../util/cache';
import { openUrl } from '../util/url';

const CONTACT_TABS = [
  {
    title: 'Nederland',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4872.9865659860625!2d4.881036!3d52.361475!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd5b42ee3519b4885!2sActive%20Capital%20Company%20B.V.!5e0!3m2!1sen!2srs!4v1568810858388!5m2!1sen!2srs',
  },
  {
    title: 'Duitsland',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.476727847869!2d11.572546014928363!3d48.139613758999225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e75f4a0d537c3%3A0x133b6a976f11c1a4!2sMaffeistra%C3%9Fe%2C%2080333%20M%C3%BCnchen%2C%20Germany!5e0!3m2!1sen!2srs!4v1600803641702!5m2!1sen!2srs',
  },
];

export default class IndexPage extends React.Component {
  responsive = {
    0: { items: 1 },
    678: { items: 2 },
    1024: { items: 3 },
    1280: { items: 4 },
  };

  homepageResponsive = {
    0: { items: 1 },
    678: { items: 1 },
    1024: { items: 1 },
  };

  cache = new InMemoryCache();

  client = new ApolloClient({
    fetchOptions: { fetch },
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  state = {};

  constructor(props) {
    super(props);

    this.state = {
      successMsg: false,
      fillFields: false,
      firstTab: true,
      secondTab: false,
      moreLoaded: false,
      spinnerShow: false,
      activeContactTab: 0,
      name: '',
      email: '',
      subject: '',
      message: '',
      teamMembers: [
        {
          id: 16,
          title: 'Albert Hartog',
          subtitle: 'Aandeelhouder en adviseur',
          slug: 'team/albert-hartog',
          description1:
            'Albert Hartog is vanaf de oprichting van Active Capital Company zeer nauw en actief betrokken als aandeelhouder en adviseur. Albert treedt op als toezichthouder bij verschillende portfolio bedrijven van Active Capital Company.',
          description2:
            'Het optimaliseren en verbeteren van bedrijven heeft hij met behulp van zijn technische en financiële achtergrond al op jonge leeftijd tot zijn specialiteit ontwikkeld. ',
          description3:
            'Voor Active Capital Company was Albert CEO van CleanLeaseFortex. Dit bedrijf heeft hij uitgebouwd van een klein familiebedrijf tot een specialist in integraal textielbeheer met meer dan 20 vestigingen. Hij heeft hier met name veel ervaring opgedaan in ‘buy and build’ en bedrijfsoptimalisatie.',
          description4:
            'Direct na zijn studie is Albert voor een periode van 9 jaar gaan werken in Zuid-Afrika, waar hij met zijn werktuigbouwkundige achtergrond veel ervaring heeft opgedaan in het verbeteren van bedrijven in de automotive- en mijnbouwindustrie.',
        },
        {
          id: 17,
          title: 'Max Groeneveld',
          subtitle: 'Senior Adviseur',
          slug: 'team/max-groeneveld',
          description1:
            'Max Groeneveld is senior adviseur van Active Capital Company. Naast zijn rol bij Active Capital Company maakt Max deel uit van het senior team van EY-Parthenon. Max is al 20 jaar partner bij OC&C Strategy Consultants, voordat het in 2016 onderdeel werd van EY. In de loop der jaren heeft Max leiding gegeven aan de Global PE-praktijk en de Global Consumer-praktijk van OC&C Strategy Consultants en heeft hij een verscheidenheid aan corporate en PE-klanten in verschillende sectoren ondersteund.',
          description2:
            'Bij Active Capital Company treedt Max op als adviseur voor zowel de Portfolio Management- als Investment Management teams. Max is arts en heeft een MBA van Stanford University.',
          description3: '',
        },
        {
          id: 1,
          title: 'Dick Zeldenthuis',
          subtitle: 'Managing Partner',
          slug: 'team/dick-zeldenthuis',
          description1:
            'Dick Zeldenthuis is Managing Partner van Active Capital Company. Voordat Dick Active Capital Company oprichtte, was hij CFO van JVC Benelux B.V. Mede als gevolg van sterke prijserosie heeft Dick zich binnen JVC Benelux B.V. beziggehouden met kostenbeheersing, liquiditeitsprognoses en het uitvoeren van een ingrijpende reorganisatie om de onderneming weer winstgevend te maken. Dick begon zijn loopbaan bij KPMG Accountants in Den Haag.',
          description2:
            'Binnen Active Capital Company is Dick met name betrokken bij het actief ondersteunen van de directieteams van de portfolio bedrijven op financieel, strategisch en operationeel gebied. Portfolio bedrijven waarin Dick een actieve rol vervult zijn Hendrik Veder Group, Smit Thermal Solutions, FTNON en Lantor.',
          description3:
            'Dick is afgestudeerd als bedrijfskundige aan de Erasmus Universiteit in Rotterdam en heeft vervolgens de postdoctorale opleiding tot Registeraccountant afgerond. Eveneens heeft hij een studie Business Valuation gevolgd aan de Rotterdam School of Management (RSM).',
        },
        {
          id: 2,
          title: 'Mels Huige',
          subtitle: 'Partner',
          slug: 'team/mels-huige',
          description1:
            'Mels Huige is in 2013 toegetreden tot Active Capital Company als Investment Director en werd in 2015 partner. Hij beschikt over uitgebreide ervaring in het begeleiden van fusie- en overnametrajecten, het structureren van acquisities en het waarderen van bedrijven. Binnen Active Capital Company is hij verantwoordelijk voor het verwerven van nieuwe deelnemingen, add-ons en uitstaptrajecten.',
          description2:
            'Eerder werkte hij als Director bij Boer & Croon Corporate Finance. Binnen Boer & Croon Corporate Finance richtte Mels zich op fusie- en overnameprocessen binnen de sectoren Transport & Logistiek en Handel & Industrie. Daarnaast heeft Mels ervaring met leveranciers van de maritieme olie- en gasindustrie. Mels is afgestudeerd in bedrijfseconomie aan de Universiteit van Maastricht en heeft een postdoctorale opleiding in Business Valuation afgerond aan de Rotterdam School of Management. Hij is tevens bestuurslid van de Nederlandse Vereniging van Participatiemaatschappijen.',
          description3: '',
        },
        {
          id: 21,
          title: 'Hartwig Ostermeyer',
          subtitle: 'Partner',
          slug: 'team/hartwig-ostermeyer',
          description1:
            'Hartwig Ostermeyer is Partner bij Active Capital Company. Hij leidt onze Duitse activiteiten in München en is verantwoordelijk voor de deal sourcing, het screenen van investeringsmogelijkheden en het beheer van de portefeuille.',
          description2:
            "Hartwig begon zijn professionele carrière in M&A in Londen en richtte zich daar op de energiesector. Daarna werkte hij als projectleider bij Boston Consulting Group in het kantoor in München en deed hij operationele ervaring op als manager bij Sixt. Hartwig heeft een 'Diplom-Ingenieur' van de Technische Universiteit van Berlijn.",
        },
        {
          id: 26,
          title: 'Boudewijn Brouwer',
          subtitle: 'Investment Director Special Investments',
          slug: 'team/boudewijn-brouwer',
          description1: `Boudewijn Brouwer ist bei Active Capital Company-Special Investments mitverantwortlich für Deal Sourcing, das Screening von Investitionsmöglichkeiten und die Durchführung von (Add-on-)Akquisitionen und Veräußerungen. Boudewijn verarbeitet Informationen schnell und versteht die Geschichte hinter den Zahlen. Sein Enthusiasmus und sein breites Interesse ermöglichen es ihm, kreative Lösungen zu finden, wenn ein Unternehmen mit herausfordernden Fragestellungen konfrontiert wird.`,
          description2: `Bevor Boudewijn zu Active Capital Company kam, arbeitete er 10 Jahre lang für ABN AMRO und sammelte Erfahrungen in den Bereichen Asset & Liability Management, Debt Capital Markets und Financial Restructuring. Boudewijn hat einen Bachelor-Abschluss in Medical Natural Sciences und Business Economics, einen Master-Abschluss in Finance und ist CFA Charterholder.`,
          description3: '',
        },
        {
          id: 9,
          title: 'Leonard Spigt',
          subtitle: 'Senior Investment Manager',
          slug: 'team/leonard-spigt',
          description1:
            'Leonard Spigt is Senior Investment Manager in het Active Capital Company team. Binnen Active Capital Company is Leonard mede verantwoordelijk voor deal-sourcing, screening van investeringsproposities, deal executie en ondersteuning van portfolio bedrijven. Leonard heeft een Masters opleiding in Finance & Investments van de Erasmus Universiteit in Rotterdam gevolgd.',
          description2: '',
          description3: '',
        },
        {
          id: 18,
          title: 'Jorgos van Rooijen',
          subtitle: 'Senior Investment Manager',
          slug: 'team/jorgos-van-rooijen',
          description1:
            'Senior Investment Manager Jorgos van Rooijen is mede verantwoordelijk voor het aantrekken van nieuwe participaties en het uitvoeren van (add-on) acquisities en desinvesteringen.',
          description2:
            'Eerder heeft Jorgos brede ervaring opgedaan op het gebied van finance en M&A transacties binnen de assurance en corporate finance praktijk van PwC.',
          description3:
            'Jorgos heeft bedrijfskunde gestudeerd en de postdoctorale opleiding tot Registeraccountant gevolgd aan de Erasmus Universiteit te Rotterdam.',
        },
        {
          id: 28,
          title: 'Maximiliaan Maaskant',
          subtitle: 'Senior Investment Manager',
          slug: 'team/maximiliaan-maaskant',
          description1:
            'Maximiliaan Maaskant is Investment Manager bij Active Capital Company. Als Investment Manager is hij medeverantwoordelijk voor deal-sourcing, screening van investeringsproposities en het uitvoeren van (add-on) acquisities en desinvesteringen.',
          description2:
            'Voordat Maximiliaan bij Active Capital Company begon, werkte hij als Investment Manager bij verschillende (familie) investeringsfondsen en ING. Hij heeft brede ervaring opgedaan op het gebied van private equity en transactie financieringen. Maximiliaan heeft een Master in Finance aan de VU in Amsterdam.',
          description3: '',
        },
        {
          id: 25,
          title: 'Robin Baaij',
          subtitle: 'Investment Manager',
          slug: 'team/robin-baaij',
          description1:
            'Robin Baaij is Investment Manager bij Active Capital Company. Als Investment Manager is Robin medeverantwoordelijk voor deal-sourcing, screening van investeringsproposities, add-ons en ondersteuning van portfolio bedrijven.',
          description2:
            'Voordat Robin bij Active Capital Company begon, werkte hij als Associate bij Strategy & Amsterdam, waar hij zich bezighield met deals en transformaties in de (duurzame) energiesector. Robin heeft een Master in Lucht- en Ruimtevaarttechniek aan de TU Delft en een MBA van INSEAD.',
          description3: '',
        },
        {
          id: 23,
          title: 'Johannes Sensmeyer',
          subtitle: 'Investment Manager',
          slug: 'team/johannes-sensmeyer',
          description1:
            'Johannes Sensmeyer is Investment Manager bij Active Capital Company in ons Duitse kantoor in München. Binnen Active Capital Company is Johannes verantwoordelijk voor de screening van investeringsmogelijkheden en ondersteunt onze portfoliobedrijven als investment manager, voornamelijk met betrekking tot de Duitse markt.',
          description2:
            'Voordat Johannes bij Active Capital Company in dienst trad, was Johannes assistent van de CFO van Commerzbank AG. Hij heeft ook ervaring opgedaan als in-house consultant, betrokken bij M&A en digitale transformatieprojecten.',
          description3:
            'Johannes heeft een Master opleiding in bedrijfskunde behaald aan de Universiteit van Mannheim.',
        },
        {
          id: 34,
          title: 'Fabian Steppi',
          subtitle: 'Investment Manager',
          slug: 'team/fabian-steppi',
          description1:
            'Fabian Steppi is Investment Manager bij Active Capital Company. Als Investment Manager is Fabian medeverantwoordelijk voor deal-sourcing, screening van investeringsmogelijkheden en uitvoering van (add-on) acquisities en exits.',
          description2:
            'Voordat Fabian bij Active Capital Company begon, werkte hij als Investment Manager bij Accursia Capital, waar hij ervaring opdeed in Industrials Private Equity. Daarvoor begeleidde hij verschillende sell- en buy-side transacties als M&A-adviseur bij MP Corporate Finance in Wenen. Fabian heeft een bachelordiploma in Finance & Management en een MBA van',
          description3:
            '',
        },
        {
          id: 10,
          title: 'Berend Jan van Meerten',
          subtitle: 'Investment Manager',
          slug: 'team/berend-jan-van-meerten',
          description1:
            'Berend Jan van Meerten werkt als Investment Associate bij Active Capital Company. Binnen Active Capital Company is Berend Jan medeverantwoordelijk voor screening van investeringsproposities, deal executie, add-on acquisities en ondersteuning van portfolio bedrijven. ',
          description2:
            'Berend Jan is begonnen als intern bij Active Capital Company en heeft een Masters opleiding Quantitative Finance aan de Erasmus Universiteit Rotterdam gevolgd.',
          description3: '',
        },
        {
          id: 31,
          title: 'Rivkele Kastelein',
          subtitle: 'Group Controller',
          slug: 'team/rivkele-kastelein',
          description1:
            'Rivkele Kastelein is Group Controller voor Active Capital Company. Zij is verantwoordelijk voor onze fondsadministratie, financiële rapportages en compliance.',
          description2:
            'Voordat Rivkele bij Active Capital Company kwam, werkte ze voor BDO Accountants en deed ze brede ervaring op met betrekking tot financiële rapportages en het samenstellen van jaarrekeningen. Rivkele heeft van 2018 - 2020 een loopbaanonderbreking ingelast om samen met haar man de wereld rond te reizen. Tijdens deze reis deed ze veel nieuwe ervaringen op en kwam ze in contact met verschillende culturen.',
          description3: '',
        },
        {
          id: 27,
          title: 'Miliaan Zwolle',
          subtitle: 'Investment Analyst',
          slug: 'team/miliaan-zwolle',
          description1: `Miliaan Zwolle is werkzaam als Investment Analyst bij Active Capital Company. Miliaan is medeverantwoordelijk voor de screening van investeringsproposities, deal executie en het begeleiden van portfolio bedrijven. Miliaan is begonnen als stagiair bij Active Capital Company en heeft een masterdiploma in Quantitative Finance aan de Erasmus Universiteit Rotterdam.`,
          description2: '',
          description3: '',
        },
        {
          id: 33,
          title: 'Constantijn van Wandelen',
          subtitle: 'Investment Analyst',
          slug: 'team/constantijn-van-wandelen',
          description1:
            'Constantijn van Wandelen is na een succesvolle stage, welke hij begon in mei 2022, aan de slag gegaan als Investment Analyst bij Active Capital Company. Hij studeerde af in de MSc International Management /CEMS en MSc Finance and Investments aan de Erasmus Universiteit Rotterdam en heeft vervolgens twee stages gelopen binnen M&A en Venture Capital.',
          description2:
            'Constantijn woonde in de Dominicaanse Republiek tijdens zijn tienerjaren waardoor hij culturele verschillen en verschillende (bedrijfs)omgevingen is gaan waarderen. Momenteel woont hij in Amsterdam en geniet hij ervan om te koken en goed eten teproeven, tennis te spelen en te lezen.',
          description3: '',
        },
        {
          id: 14,
          title: 'Célaine Tates',
          subtitle: 'Office Manager',
          slug: 'team/celaine-tates',
          description1:
            'Célaine Tates is Office Manager bij Active Capital Company. Zij is verantwoordelijk voor de secretariële ondersteuning van de partners en voor diverse facilitaire zaken binnen Active Capital Company.',
          description2:
            'Célaine is onder andere werkzaam geweest als Personal Assistant en Events medewerker bij Houthoff.',
          description3: '',
        }
      ],
      homepageSlider: [
        {
          id: 1,
          link: '/nl/portfolio/?amphitec',
          text: '',
          imageName: 'homepage-amphitec',
          imageNameHover: '',
          imageClass: 'homepage-amphitec-logo',
        },
        {
          id: 2,
          link: '/nl/portfolio/?codi_group',
          text: '',
          imageName: 'homepage-codi',
          imageNameHover: '',
          imageClass: 'homepage-codi-logo',
        },
        {
          id: 3,
          link: '/nl/portfolio/?technobis',
          text: '',
          imageName: 'homepage-technobis',
          imageNameHover: '',
          imageClass: 'homepage-technobis-logo',
        },
      ],
      inlineStyleNavigation: {
        top: 100,
      },
      inlineStyleArrow: {
        width: 111,
        height: 111,
      },
      dataLoaded: false,
    };
    this.handleResize();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidMount() {
    const promises = [loadByKey('news', 'nl'), loadByKey('portfolios', 'nl')];

    Promise.all(promises)
      .then(([news, portfolio]) => {
        this.setState(
          {
            news,
            portfolio,
          },
          () => {
            this.setState({
              dataLoaded: true,
            });

            const queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams.has('scroll-to')) {
              const scrollToElement = queryParams.get('scroll-to');
              scrollTo(document.getElementById(scrollToElement));
            }
          }
        );
      })
      .catch(err => console.log('ERR', err));
  }

  handleResize = () => {
    if (typeof window !== 'undefined') {
      var sliderItem = document.getElementById('slider-item-1');

      if (sliderItem !== null) {
        const me = this;
        setTimeout(() => {
          me.setState({
            inlineStyleNavigation: {
              top: sliderItem.offsetHeight + 2,
            },
          });
          me.setState({
            inlineStyleArrow: {
              width: sliderItem.offsetWidth / 4,
              height: sliderItem.offsetWidth / 4,
            },
          });
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      var sliderItem = document.getElementById('slider-item-1');

      if (sliderItem !== null) {
        const me = this;
        setTimeout(() => {
          me.setState({
            inlineStyleNavigation: {
              top: sliderItem.offsetHeight + 2,
            },
          });
          me.setState({
            inlineStyleArrow: {
              width: sliderItem.offsetWidth / 4,
              height: sliderItem.offsetWidth / 4,
            },
          });
        });
      }
    }
  };

  toggleDetails = (e, key) => {
    e.preventDefault();
    const elements = document.querySelectorAll('#team-member-' + key);

    if (elements.length === 0) {
      return;
    }

    this.toggleDetailsOfAllTeamMembers(elements);

    elements.forEach(element => {
      const parentElement = element.closest('.alice-carousel__stage-item');

      const childNodes = parentElement.childNodes;
      const teamImage = childNodes[0].children[0].childNodes[0];
      const teamImageHeight = teamImage.offsetHeight;

      teamImage.style.height = teamImageHeight.toString() + 'px';
      parentElement.classList.toggle('opened');
      element.classList.toggle('opened');
    });
  };

  toggleDetailsOfAllTeamMembers = skippableElements => {
    const allTeamMembers = document.querySelectorAll('.team-member-info');
    allTeamMembers.forEach(element => {
      if (skippableElements) {
        const foundSkippableElement = [...skippableElements].find(e =>
          e.isSameNode(element)
        );
        if (foundSkippableElement) {
          return;
        }
      }

      const parentElement = element.closest('.alice-carousel__stage-item');

      const childNodes = parentElement.childNodes;
      const teamImage = childNodes[0].children[0].childNodes[0];
      const teamImageHeight = teamImage.offsetHeight;

      teamImage.style.height = teamImageHeight.toString() + 'px';
      parentElement.classList.remove('opened');
      element.classList.remove('opened');
    });
  };

  handleNextTeamMemberClick = () => {
    this.toggleDetailsOfAllTeamMembers();
    this.Carousel.slideNext();
  };

  handlePrevTeamMemberClick = () => {
    this.toggleDetailsOfAllTeamMembers();
    this.Carousel.slidePrev();
  };

  setInitialItemsHeight = () => {
    setTimeout(() => {
      const slideItem1 = document.getElementById('slide-item-1');
      let initialHeight = 475;
      if (slideItem1 !== null) {
        initialHeight =
          slideItem1.childNodes[0].childNodes[0].childNodes[0].offsetHeight;
      }
      const imageWrappers = document.querySelectorAll('.team-image-wrapper');
      imageWrappers.forEach(element => {
        element.style.height = initialHeight.toString() + 'px';
      });
    }, 1000);
  };

  /**
   * Checks if object is empty.
   */
  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  toggleMore = () => {
    this.setState({ moreLoaded: !this.state.moreLoaded });
  };

  portfolioData() {
    let portfolio = [];
    if (this.state.portfolio) {
      for (let item of this.state.portfolio) {
        if (item.category === 'current') {
          portfolio.push(item);
        }
      }
    }
    if (portfolio === undefined || portfolio.length === 0) {
      return false;
    }
    if (portfolio.length !== 0) {
      return portfolio.map((val, index) => {
        if (!val.active) {
          return;
        }
        return (
          <div
            className="single-portfolio-wrapper col-md-6 col-lg-6 col-xl-4 float-left"
            key={index}
          >
            <Link to={'/nl/portfolio/?' + val.slug}>
              <span className="portofolio-image-wrapper">
                <img
                  src={
                    val.portfolio_image !== undefined &&
                    val.portfolio_image !== null
                      ? `${this.apiEndPoint}/${val.portfolio_image.url}`
                      : noImage
                  }
                  alt=""
                />
                <div className="image-overlay-05-black"></div>
                {val.company_logo && (
                  <img
                    className="logo-img"
                    src={`${this.apiEndPoint}/${val.company_logo.url}`}
                    alt=""
                  />
                )}
              </span>
              <span className="subtitle">{val.company_name}</span>
              <span className="title news-excerpt">{val.business}</span>
            </Link>
            <Link to={'/portfolio/?' + val.slug} className="more-info">
              Lees meer
            </Link>
          </div>
        );
      });
    }
    return <span className="no-data-info">No data available</span>;
  }

  divestedPortfolios() {
    let portfolio = [];
    if (this.state.portfolio) {
      for (let item of this.state.portfolio) {
        if (item.category === 'divested') {
          portfolio.push(item);
        }
      }
    }
    if (portfolio === undefined || portfolio.length === 0) {
      return false;
    }
    if (portfolio.length !== 0) {
      return portfolio.map((val, index) => {
        if (!val.active) {
          return;
        }
        return (
          <div
            className="single-portfolio-wrapper col-md-6 col-lg-6 col-xl-4 float-left"
            key={index}
          >
            <Link to={'/nl/portfolio/?' + val.slug}>
              <span className="portofolio-image-wrapper">
                <img
                  src={
                    val.portfolio_image !== undefined &&
                    val.portfolio_image !== null
                      ? `${this.apiEndPoint}/${val.portfolio_image.url}`
                      : noImage
                  }
                  alt=""
                />
                <div className="image-overlay-05-black"></div>
                {val.company_logo && (
                  <img
                    className="logo-img"
                    src={`${this.apiEndPoint}/${val.company_logo.url}`}
                    alt=""
                  />
                )}
              </span>
              <span className="subtitle">{val.company_name}</span>
              <span className="title news-excerpt">{val.business}</span>
            </Link>
            <Link to={'/portfolio/?' + val.slug} className="more-info">
              Lees meer
            </Link>
          </div>
        );
      });
    }
    return <span className="no-data-info">No data available</span>;
  }

  sendContactMail = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.subject === '' ||
      this.state.message === ''
    ) {
      /*
        store.addNotification({
          message: 'Vul alstublieft alle velden in.',
          title: 'Waarschuwing',
          type: 'warning',
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        */
      this.setState({ fillFields: true });
      setTimeout(() => {
        this.setState({ fillFields: false });
      }, 2000);
      return;
    }

    this.setState({ spinnerShow: true });
    setTimeout(() => {
      this.setState({ spinnerShow: false });
      this.setState({ successMsg: true });
      setTimeout(() => {
        this.setState({ successMsg: false });
      }, 3000);
    }, 2000);

    return;
    const data = {
      to: ['andric.stevan@yahoo.com', 'nikola@cru.rs'],
      from: this.state.email,
      replyTo: this.state.email,
      subject: this.state.subject,
      text: this.state.message,
      html:
        this.state.name +
        ' heeft je een bericht gestuurd: <br><p>' +
        this.state.message +
        '</p>',
    };

    this.setState({ spinnerShow: true });
    axios
      .post(`${this.apiEndPoint}/email/`, data)
      .then(response => {
        store.addNotification({
          message: 'Bericht is succesvol verzonden.',
          title: 'Success',
          type: 'success',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch(err => {
        console.error(err);
        store.addNotification({
          message: 'Something went wrong, please try again later.',
          title: 'Error',
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .finally(() => {
        this.setState({ spinnerShow: false });
      });
  };

  notificationMesagge() {
    return (
      <div className="success-mesagge">
        <p>Uw bericht is succescol verzonden.</p>
        <p>We zullen u zo snel mogelijk antwoorden.</p>
      </div>
    );
  }

  filedsMesagge() {
    return (
      <div className="fields-mesagge">
        <p>Please fill all fields.</p>
      </div>
    );
  }

  /**
   * It generates list of news items to be shown.
   */
  listItems() {
    let items = this.state.news;
    let options = { month: 'long', day: '2-digit', year: 'numeric' };
    if (items === undefined || items.length === 0) {
      return false;
    }

    return (
      <div className="row news-container">
        {items.map((val, index) => {
          if (index > 5) {
            return;
          }

          return (
            <Link
              to={'/nl/news-details/?' + val.slug}
              className="col-lg-6 news-box"
              key={index}
            >
              <div className="float-left news-image">
                <img
                  width="100%"
                  src={
                    val.heading_image !== undefined &&
                    val.heading_image !== null
                      ? `${this.apiEndPoint}/${val.heading_image.url}`
                      : noImage
                  }
                  alt=""
                />
              </div>
              <div className="large-padding news-text">
                <h5 className="news-title">{val.img_heading}</h5>
                <div className="news-published first-uppercase-letter">
                  <div
                    className="cursor--pointer"
                    onClick={e => openUrl(val.source_url)}
                  >
                    {val.source || 'Persbericht'},{' '}
                    {new Intl.DateTimeFormat('nl-NL', options).format(
                      new Date(val.published)
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </Link>
          );
        })}
      </div>
    );
  }

  render() {
    const { dataLoaded, activeContactTab } = this.state;

    return (
      <Layout dataLoaded={dataLoaded}>
        <Spinner show={this.state.spinnerShow} />
        <header className="homepage-header-wrapper" id="header">
          <span id="homepage-indicator"></span>

          <AliceCarousel
            responsive={this.homepageResponsive}
            dotsDisabled={true}
            autoPlay={true}
            fadeOutAnimation={true}
            autoPlayInterval={5000}
            playButtonEnabled={false}
            buttonsDisabled={true}
            preventEventOnTouchMove={false}
            stopAutoPlayOnHover={false}
            mouseDragEnabled={false}
            duration={2000}
          >
            {
              (this.items = this.state.homepageSlider.map((item, key) => (
                <div className={`masthead masthead-${item.id}`} key={key}>
                  <div className="page-wrapper absolute-wrapper-header-logo">
                    {/* <span className="background-image-text">Background image</span> */}
                    <span className="text-right homepage-mobile-hidden">
                      <a href={item.link} rel="noopener noreferrer">
                        {/*<img className="left-spacing-medium default-icon-state" src={require(`../assets/images/icons/icon-${item.imageName}.svg`)} alt=""/>*/}
                        {/*<img className="left-spacing-medium hover-icon-state" src={require(`../assets/images/icons/icon-${item.imageNameHover}.svg`)} alt=""/>*/}
                        <img
                          className={item.imageClass}
                          src={require(`../assets/images/homepage-icons/${item.imageName}.png`)}
                          alt=""
                        />
                      </a>
                    </span>
                    <span className="clearfix"></span>
                  </div>
                </div>
              )))
            }
          </AliceCarousel>
          <div className="container d-flex align-items-center homepage-absolute-wrapper">
            <div className="center-block text-center col-sm-12">
              <h1 className="mx-auto my-0 homepage-main-text">
                Wij zijn een hands-on investeringsmaatschappij die volledig
                potentieel realiseert door actieve transformatie.
              </h1>
              <div className="header-buttons-wrapper">
                <Scroll type="id" element="our-work">
                  <a
                    href="#our-work"
                    className="button-link button-white-wrapper"
                  >
                    <button className="button button-white">
                      Onze aanpak
                      <img src={iconCaretDown} alt="" />
                    </button>
                  </a>
                </Scroll>

                <Link
                  to="/nl/investment-focus"
                  className="button-link button-orange-wrapper"
                >
                  <button className="button button-orange">
                    Investeringsfocus
                    <img src={iconCaretRight} alt="Investeringsfocus" />
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="header-quick-links page-wrapper absolute-wrapper-header-quick-links container page-wrapper">
            <span className="three-column custom-hompage-three-column homepage-mobile-hidden">
              <a
                href="https://fundrbird.com/login?agent_slug=active-capital"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="right-spacing-medium default-icon-state"
                  src={iconLogin}
                  alt=""
                />
                <img
                  className="right-spacing-medium hover-icon-state"
                  src={iconLoginHover}
                  alt=""
                />
                Investeerders log in
              </a>
            </span>
            <span className="three-column text-center">
              <Scroll type="id" element="about">
                <img
                  className="default-icon-state icon-down"
                  src={iconDown}
                  alt=""
                />
              </Scroll>
            </span>
            <span className="clearfix"></span>
          </div>
        </header>

        <section className="container content-wrapper" id="about">
          <div className="homepage-title text-center">
            <h2 className="text-left">
              Onze kernwaarden: Passie, Ondernemerschap, Resultaat
            </h2>
            <span className="horizontal-line gray-background homepage-line homepage-desktop-hidden"></span>
            <div className="col-sm-6 text-left float-left custom-right-padding">
              <p>
                Active Capital Company investeert in MKB-bedrijven gevestigd in
                Nederland of Duitsland met een omzet tussen de € 10M - € 100M.
                Deze bedrijven zijn actief in productie, (technische)
                groothandels of zakelijke dienstverlening.
              </p>
            </div>
            <div className="col-sm-6 text-left float-left custom-left-spacing">
              <p>
                ACC is opgericht en geïnspireerd door ondernemers met een passie
                voor industrieën die streven naar ongekende resultaten door onze
                drie voornaamste drijfveren: Geografische uitbreiding,
                duurzaamheid en innovatie.
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <span className="horizontal-line gray-background homepage-line homepage-mobile-hidden"></span>
          <div className="about-wrapper">
            <div className="col-md-6 text-left float-left custom-right-spacing homepage-disable-padding">
              <div className="gray-background medium-padding">
                <h3>
                  Duurzame rendementen gebaseerd op drie belangrijke componenten{' '}
                </h3>
              </div>
            </div>
            <div className="col-sm-6 float-left custom-left-spacing">
              <img
                className="about-geographic"
                src={iconGeography}
                alt="Geografische expansie"
              />
              <h4 className="fs-1_5rem">
                Geografische <br />
                expansie
              </h4>
              <p>
                We breiden de geografische reikwijdte uit via nieuwe
                faciliteiten en sales teams over de hele wereld
              </p>
            </div>
            <div className="clearfix"></div>
            <div className="col-sm-6 float-left custom-right-spacing custom-top-spacing">
              <img src={iconInovation} alt="Innovatie" />
              <h4 className="fs-1_5rem">Innovatie</h4>
              <p>
                We acceleren de groei door het toevoegen van bedrijfsactiviteten
                en productontwikkeling
              </p>
            </div>
            <div className="col-sm-6 float-left custom-left-spacing custom-top-spacing">
              <img src={iconPlanet} alt="Impact" />
              <h4 className="fs-1_5rem">Impact</h4>
              <p>
                We focussen op duurzaamheidskarakteristieken om de
                winstgevendheid te verhogen en investeren in mens, milieu en
                cultuur
              </p>
            </div>
            <div className="clearfix"></div>
          </div>

          <div className="investment-criteria">
            <Link to="/nl/investment-focus">
              <img src={iconRightBlue} alt="investeringsfocus" />
              Bekijk onze investeringsfocus
            </Link>
          </div>

          <div className="key-facts-wrapper">
            <div className="col-lg-6 float-left key-facts-image">
              <img src={keyFactsImage} alt="Feiten" className="right-padding" />
            </div>

            <div className="col-lg-6 float-left">
              <h3>Feiten</h3>
              <span className="horizontal-line gray-background no-side-margin"></span>
              <h4>
                3711 <br className="visible-mobile" />
                <sup>Werknemers</sup>
              </h4>
              <p>Werkzaam binnen 10 bedrijven in 11 landen</p>
              <h4>
                € 184M
                <br className="visible-mobile" />
                <sup> Activa onder beheer</sup>
              </h4>
              <p>Verdeeld over 4 fondsen</p>
            </div>

            <div className="clearfix"></div>
          </div>

          <div className="our-work-wrapper" id="our-work">
            <div className="col-sm-12">
              <h3 className="top-separator">De ACC manier</h3>
              <span className="horizontal-line gray-background no-side-margin"></span>
            </div>
            <div className="our-work-wrapper-section">
              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-1" className="link-label">
                  <span className="blue-color mobile-hidden">
                    <strong>Fase 1</strong>
                  </span>
                  <span className="horizontal-line blue-background no-side-margin mobile-hidden"></span>
                  <img src={iconMoney} alt="Fase 1" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-1"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Transactie</h4>
                      <p>Waarom en hoe we investeren</p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(1)}
                          className="list-wrapper-1"
                        >
                          <span className="list-basic-1">
                            Onderzoek naar de kans van slagen van de
                            investering.
                          </span>
                          <span className="list-detailed-1 hidden">
                            Diepgaand onderzoek naar de kansen binnen onze{' '}
                            <a
                              className="orange-link"
                              href="/nl/investment-focus"
                            >
                              inverstingscriteria
                            </a>
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(2)}
                          className="list-wrapper-2"
                        >
                          <span className="list-basic-2">
                            Uitvoering van de due diligence.
                          </span>
                          <span className="list-detailed-2 hidden">
                            Uitvoering van de due diligence door ons eigen
                            <Scroll type="id" element="team">
                              <a href="?scroll-to=team" className="orange-link">
                                {' '}
                                team{' '}
                              </a>
                            </Scroll>{' '}
                            ondersteund door onze
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                partners{' '}
                              </a>
                            </Scroll>
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(3)}
                          className="list-wrapper-3"
                        >
                          <span className="list-basic-3">
                            Presentatie van een indicatieve bieding.
                          </span>
                          <span className="list-detailed-3 hidden">
                            Presentatie van een indicatieve bieding en
                            commerciële due diligence om de toekomstige
                            strategie te bespreken
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(4)}
                          className="list-wrapper-4"
                        >
                          <span className="list-basic-4">
                            Opstellen van een 100-dagen plan.
                          </span>
                          <span className="list-detailed-4 hidden">
                            Het opstellen van een 100-dagen plan in nauwgezette
                            samenwerking met de verkoper en experts uit de
                            industrie
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-1"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>

              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-2" className="link-label">
                  <span className="orange-color mobile-hidden">
                    <strong>Fase 2</strong>
                  </span>
                  <span className="horizontal-line orange-background no-side-margin mobile-hidden"></span>
                  <img src={iconTransform} alt="Fase 2" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-2"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Transformatie</h4>
                      <p>Planning en excecutie</p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(5)}
                          className="list-wrapper-5"
                        >
                          <span className="list-basic-5">
                            We steken veel tijd in onze portfolio bedrijven.
                          </span>
                          <span className="list-detailed-5 hidden">
                            We brengen vier dagen per week door bij onze
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                portfolio bedrijven{' '}
                              </a>
                            </Scroll>{' '}
                            om ACC-standaarden te implementeren, capaciteit te
                            creëren en groei te realiseren
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(6)}
                          className="list-wrapper-6"
                        >
                          <span className="list-basic-6">
                            Groei versnellen.
                          </span>
                          <span className="list-detailed-6 hidden">
                            Het versnellen van groei door te investeren in
                            innovaties door bedrijfstoevoegingen en product
                            ontwikkeling
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(7)}
                          className="list-wrapper-7"
                        >
                          <span className="list-basic-7">
                            Expanderen van de geografische
                          </span>
                          <span className="list-detailed-7 hidden">
                            Het uitbreiden van de geografische gebieden met
                            behulp van nieuwe faciliteiten en sales teams in
                            andere landen
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(8)}
                          className="list-wrapper-8"
                        >
                          <span className="list-basic-8">
                            Transformeren van de bedrijfscultuur.
                          </span>
                          <span className="list-detailed-8 hidden">
                            Het Transformeren van de bedrijfscultuur met een
                            focus op duurzaamheid
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-2"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>

              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-3" className="link-label">
                  <span className="soft-red-color mobile-hidden">
                    <strong>Fase 3</strong>
                  </span>
                  <span className="horizontal-line soft-red-background no-side-margin mobile-hidden"></span>
                  <img src={iconMap} alt="Fase 3" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-3"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Positionering en exit</h4>
                      <p>
                        Planning en excecutie van het
                        <br />
                        verkoop-proces
                      </p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(9)}
                          className="list-wrapper-9"
                        >
                          <span className="list-basic-9">
                            Focus op exit positionering.
                          </span>
                          <span className="list-detailed-9 hidden">
                            Gedurende de holding periode houden we met elke
                            beslissing focus op de exit positionering
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(10)}
                          className="list-wrapper-10"
                        >
                          <span className="list-basic-10">
                            Vaststellen van optimaal exit-moment.
                          </span>
                          <span className="list-detailed-10 hidden">
                            Het vaststellen van een optimal exit moment voor het
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                portfolio{' '}
                              </a>
                            </Scroll>
                            bedrijf:
                            <ul className="the-way-list">
                              <li>Organisatie</li>
                              <li>Cultuur</li>
                              <li>Groei</li>
                              <li>Innovatie</li>
                            </ul>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-3"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>

        <section
          className="container content-wrapper business-invested mobile-padding"
          id="portfolio"
        >
          <h3 className="top-separator">Portfolio</h3>
          <div className="tabs-wrapper">
            <div
              className={this.state.firstTab ? 'tab-link active' : 'tab-link'}
              onClick={this.showTab1}
            >
              Huidige portfolio
            </div>
            <span className="vertical-separator homepage-mobile-hidden"></span>
            <hr className="short-horizontal-line" />
            <div
              className={this.state.secondTab ? 'tab-link active' : 'tab-link'}
              onClick={this.showTab2}
            >
              Voormalig portfolio
            </div>
          </div>
          <div className="clearfix"></div>
          <span className="horizontal-line gray-background no-side-margin"></span>

          <div className="row">
            <input
              type="checkbox"
              className="read-more-state"
              id="portfolio-1"
            />
            <div
              className={
                'current-investment-wrapper read-more-wrap' +
                (!this.state.firstTab ? ' hide' : '') +
                (this.state.moreLoaded ? ' more-loaded' : '')
              }
            >
              {this.portfolioData()}
              <label
                htmlFor="portfolio-1"
                className="read-more-trigger"
                onClick={this.toggleMore}
              ></label>
            </div>

            <div
              className={
                'divested-wrapper' + (!this.state.secondTab ? ' hide' : '')
              }
            >
              {this.divestedPortfolios()}
            </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <section className="team-title-container" id="team">
          <h3 className="text-center top-separator section-spaces-bottom">
            Het team
          </h3>
        </section>
        <section className="team-wrapper col-sm-12">
          <AliceCarousel
            responsive={this.responsive}
            dotsDisabled={true}
            buttonsDisabled={true}
            ref={el => (this.Carousel = el)}
            onInitialized={this.setInitialItemsHeight}
            onResized={this.setInitialItemsHeight}
          >
            {
              (this.items = this.state.teamMembers.map((item, key) => (
                <div
                  id={`slide-item-` + key}
                  onClick={e => this.toggleDetails(e, key)}
                  key={key}
                >
                  <a href="#" key={key}>
                    <div className="team-image-wrapper">
                      <img
                        src={require(`../assets/images/team/team-${item.id}.jpg`)}
                        alt={item.title}
                        id={`slider-item-` + key}
                      />
                    </div>
                    <div className="title">{item.title}</div>
                    <div className="subtitle">{item.subtitle}</div>
                  </a>
                  <div className="team-member-info" id={`team-member-${key}`}>
                    <span className="member-info">
                      <p className="black-color">{item.description1}</p>
                      <p>{item.description2}</p>
                      <p>{item.description3}</p>
                      {item.description4 && <p>{item.description4}</p>}
                    </span>
                  </div>
                </div>
              )))
            }
          </AliceCarousel>

          <div
            className="navigation-buttons-container"
            style={this.state.inlineStyleNavigation}
          >
            <button
              className="alice-carousel__prev-btn"
              style={this.state.inlineStyleArrow}
              onClick={() => this.handlePrevTeamMemberClick()}
            >
              <p className="alice-carousel__prev-btn-item">
                <span></span>
              </p>
            </button>
            <button
              className="alice-carousel__next-btn"
              style={this.state.inlineStyleArrow}
              onClick={() => this.handleNextTeamMemberClick()}
            >
              <p className="alice-carousel__next-btn-item">
                <span></span>
              </p>
            </button>
          </div>
        </section>

        {/* End of team section */}

        <section
          className="container content-wrapper responsible-investments-title-container"
          id="responsible-investments"
        >
          <h3 className="text-center top-separator section-spaces-bottom">
            Verantwoordelijk Investeren
          </h3>
          <h4 className="text-center section-spaces-bottom">
            Bij Active Capital Company vinden we de impact van onze portfolio
            ondernemingen op de omgeving en samenleving belangrijk. We splitsen
            impact onderwerpen over drie pilaren:
          </h4>
        </section>
        <section className="container content-wrapper responsible-investments-wrapper">
          <div className="row">
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconReporting}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Rapportage & wetgeving
                </span>
                <span className="responsible-investments-text">
                  Wij moedigen onze bedrijven aan om transparanter te worden op
                  het gebied van Environmental, Social and Governance (ESG)
                  gerelateerde onderwerpen en andere niet- financiële
                  overwegingen
                </span>
              </div>
            </div>
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconMagnifierRemove}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Impact reductie
                </span>
                <span className="responsible-investments-text">
                  Het reduceren van de impact van de onderneming op het gebied
                  van ESG-gerelateerde onderwerpen zoals CO2 uitstoot en
                  waterverbruik. Indien relevant zullen management en ACC
                  ondersteund worden door adviseurs en derden
                </span>
              </div>
            </div>
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconSustainable}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Waardecreatie
                </span>
                <span className="responsible-investments-text">
                  Het veranderen van bedrijfsmodellen om duurzamer en
                  veerkrachtiger te worden voor toekomstige marktdynamieken.
                  Duurzaamheidskarakeristieken worden meegenomen bij het bepalen
                  van de toekomstige strategie van een onderneming
                </span>
              </div>
            </div>
          </div>

          <Link
            to="https://activecapitalcompany.nl/ACC_ESG-related_disclosures_Nov_2022.pdf"
            target="_blank"
            className="more-info"
          >
            Klik hier voor onze ESG-gerelateerde publicaties
          </Link>
        </section>

        {/* Start of Timbo section */}

        <section
          className="container content-wrapper corporate-wrapper col-sm-12"
          id="corporate-responsibility"
        >
          <div className="">
            <div className="col-md-6 float-left corporate-first-wrapper">
              <div className="timbo-image-wrapper desktop-hidden">
                <img src={timboAfrica} alt="Timbo Africa Foundation" />
              </div>
              <img
                src={timboLogo}
                alt="Timbo Africa Foundation"
                width="160px"
                className="timbo-logo"
              />
              <br />
              <h3>Timbo Africa Foundation</h3>
              <br />
              <p>
                Stichting Timbo zet zich in ter bescherming van wildlife in
                Afrika door middle van het creeren van corridors tussen
                verschillende natuurreservaten. Middels een corridor kan
                wildlife ongestoord migreren. Daarnaast, zet ze zich in om
                lokale jeugd betrokken te laten geraken bij onderzoek, training
                en kennis overdracht om hen te helpen bij het maken van de
                eerste stappen in een carrière in natuurconservatie
              </p>
              <p>
                De stichting opereert uitsluitend in Botswana en Zuid-Afrika,
                maar zet zich ook in om financiering op te halen voor
                kanker-onderzoek in Nederland
              </p>
              <br />
              <Link to="/nl/corporate-responsibility" className="button-link">
                <button className="button button-blue">
                  Lees meer
                  <img src={iconCaretRight} alt="" />
                </button>
              </Link>
              <br />
              <br />
            </div>
            <div className="col-md-6 float-left timbo-image-wrapper mobile-hidden">
              <img src={timboAfrica} alt="Timbo Africa Foundation" />
            </div>
            <div className="clearfix"></div>
          </div>
        </section>

        <section
          className="container content-wrapper news-wrapper col-sm-12 mobile-padding "
          id="news"
        >
          <h3 className="top-separator section-spaces-bottom">
            Het laatste
            <br />
            nieuws
          </h3>
          <div className="view-all-news top-separator-large custom-view-all-news-link">
            <p>
              <Link to="/nl/news-overview">Alle nieuwsberichten</Link>
            </p>
          </div>
          <div className="clearfix"></div>
          <span className="horizontal-line gray-background no-side-margin"></span>
          <div className="bottom-spacing">
            {this.listItems()}
            <Link
              className="view-all-news-and-articles top-separator-large"
              to="/news-overview"
            >
              <p>View all news and articles</p>
            </Link>
            <div className="clearfix"></div>
          </div>
        </section>

        <section className="contact-wrapper" id="contact">
          <span className="contact-info-text filter--text-shadow--dark text-desktop-hidden">
            Neem contact op via onderstaand formulier
          </span>
          <div className="filter--darken-image">
            <img
              src={contactImage}
              alt="Contact"
              className="background-image-contact-desktop background-image text-mobile-hidden"
            />
          </div>
          <div className="filter--darken-image">
            <img
              src={contactImageMobile}
              alt="Contact"
              className="background-image text-desktop-hidden"
            />
          </div>
          <div className="container page-wrapper contact-info-wrapper">
            <div className="col-md-6 float-left contact-form-wrapper">
              <div className="contact-info-tabs">
                {CONTACT_TABS.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        activeContactTab === index ? 'active' : ''
                      } contact-tab-link`}
                      onClick={this.onTabChange(index)}
                    >
                      {tab.title}
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="google-map">
                  <iframe
                    title="Google Map"
                    src={CONTACT_TABS[activeContactTab].mapUrl}
                    width="100%"
                    height="450"
                    frameBorder="0"
                  ></iframe>
                </div>
                <div className="contact-form">
                  <form id="contact-form">
                    <input
                      type="text"
                      className="name-field"
                      id="name"
                      name="name"
                      placeholder="Uw naam"
                      value={this.state.name}
                      required
                      onChange={this.handleNameChange}
                    />

                    <input
                      type="text"
                      className="email-field"
                      id="email"
                      name="email"
                      placeholder="Jouw e-mailadres"
                      value={this.state.email}
                      required
                      onChange={this.handleEmailChange}
                    />

                    <input
                      type="text"
                      className="subject-field"
                      id="subject"
                      name="subject"
                      placeholder="Het onderwerp"
                      value={this.state.subject}
                      required
                      onChange={this.handleSubjectChange}
                    />

                    <textarea
                      className="message-field"
                      id="message"
                      name="message"
                      placeholder="Uw bericht"
                      value={this.state.message}
                      required
                      onChange={this.handleMessageChange}
                    ></textarea>

                    <button
                      className="button button-blue"
                      type="button"
                      onClick={this.sendContactMail}
                    >
                      <span className="button-text">Bericht versturen</span>
                      <img src={iconSubmit} alt="" />
                    </button>
                  </form>
                </div>
                {this.state.successMsg ? (
                  this.notificationMesagge()
                ) : (
                  <div></div>
                )}
                {this.state.fillFields ? this.filedsMesagge() : <div></div>}
              </div>
            </div>
            <div className="col-md-6 float-left no-left-padding">
              <div className="contact-info">
                <span className="contact-info-text filter--text-shadow--dark text-mobile-hidden">
                  Neem contact op via onderstaand formulier
                </span>
                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconSuitcase}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    Active Capital Company B.V.
                  </span>
                  <span className="clearfix"></span>
                </p>
                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconAddress}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    <span className="contact-info-right-title">Nederland</span>
                    <a
                      className="contact-info-right-link"
                      href="https://goo.gl/maps/mUFBVCqkTefWpyqu9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vossiusstraat 13A
                      <span className="custom-align">1071 AC Amsterdam</span>
                    </a>
                    <br />
                    <span className="contact-info-right-border"></span>
                    <br />
                    <span className="contact-info-right-title">Duitsland</span>
                    <a
                      className="contact-info-right-link"
                      href="https://goo.gl/maps/gHVZcrpjRnnpmcBh9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maffeistraße 3
                      <span className="custom-align">80333 München</span>
                    </a>
                  </span>

                  <span className="clearfix"></span>
                </p>

                <BrowserView renderWithFragment>
                  <p>
                    <img
                      className="contact-info-left-section"
                      src={iconPhone}
                      alt=""
                    />
                    <span className="contact-info-right-section">
                      <span className="contact-info-right-title">NL: </span>{' '}
                      {config.companyPhoneNumber}
                      <br />
                      <br />
                      <span className="contact-info-right-title">
                        DE:{' '}
                      </span>{' '}
                      {config.companyPhoneNumberGermany}
                    </span>
                    <span className="clearfix"></span>
                  </p>
                </BrowserView>
                <MobileView renderWithFragment>
                  <p>
                    <img
                      className="contact-info-left-section"
                      src={iconPhone}
                      alt=""
                    />
                    <span className="contact-info-phone-numbers">
                      <a
                        className="contact-info-right-section contact-info-phone"
                        href={config.companyPhoneUrl}
                      >
                        <span className="contact-info-right-title">NL: </span>
                        {config.companyPhoneNumber}
                      </a>
                      <a
                        className="contact-info-right-section"
                        href={config.companyPhoneUrlGermany}
                      >
                        <span className="contact-info-right-title">DE: </span>
                        {config.companyPhoneNumberGermany}
                      </a>
                    </span>
                    <span className="clearfix"></span>
                  </p>
                </MobileView>

                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconMessage}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    <a href={'mailto:' + config.companyEmail}>
                      {config.companyEmail}
                    </a>
                  </span>
                  <span className="clearfix"></span>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </section>
        <Scroll type="id" element="header">
          <div className="back-to-top-wrapper">
            <a href="?scroll-to=header">
              <i className="fas fa-angle-double-up"></i>
              <br />
              <span className="back-to-top">Terug naar boven</span>
            </a>
          </div>
        </Scroll>
      </Layout>
    );
  }

  showTab1 = () => {
    this.setState({
      firstTab: true,
      secondTab: false,
    });
  };

  showTab2 = () => {
    this.setState({
      firstTab: false,
      secondTab: true,
    });
  };

  onTabChange = index => () => {
    this.setState({
      activeContactTab: index,
    });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubjectChange = e => {
    this.setState({ subject: e.target.value });
  };

  handleMessageChange = e => {
    this.setState({ message: e.target.value });
  };

  handleListCollapse = index => {
    var listBasic = document.querySelector('.list-basic-' + index);
    var listDetailed = document.querySelector('.list-detailed-' + index);
    var listWrapper = document.querySelector('.list-wrapper-' + index);

    listBasic.classList.toggle('hidden');
    listDetailed.classList.toggle('hidden');
    listWrapper.classList.toggle('opened');
  };
}
